import React from "react";

import axios, { AxiosRequestConfig } from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import api from "../middleware/index";
import { SESSION_DURATION } from "./const";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,

      queryFn: ({ queryKey, pageParam }) => {
        const { params, ...config } = queryKey[1] || {};

        return api
          .get(`/${queryKey[0]}`, {
            ...config,
            params: { ...params, page: pageParam },
          })
          .then(({ data }) => data);
      },
      staleTime: SESSION_DURATION,
      //   getNextPageParam: (params) => {
      //     const { isLastPage, page } =
      //       params as unknown as APIPaginatedResponse["data"];

      //     return isLastPage ? undefined : (page || 0) + 1;
      //   },
      //   onError: (error) => {
      //     if (axios.isAxiosError(error) || error instanceof Error) {
      //     }
      //   },
      retry: (failureCount, error) => {
        if (axios.isAxiosError(error)) {
          /**
           * Do not retry requests that returned a 500
           */
          if (error?.response?.status === 500) {
            return false;
          }

          /**
           * Do not retry requests that returned a 404
           */
          if (error?.response?.status === 404) {
            return false;
          }
        }

        /**
         * Retry only up to 3 times maximum
         */
        if (failureCount <= 3) {
          return true;
        }

        return false;
      },
    },
    mutations: {
      onError: (error) => {
        if (axios.isAxiosError(error) || error instanceof Error) {
        }
      },
    },
  },
});

const Provider = ({ children }) => {
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};

export { Provider as QueryClientProvider, client };
